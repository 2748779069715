<template>
<div>
    <myhead :param="param" />
    <section>
        <div class="bannergflamp">
            <div style="height:280px; "></div>
            <div style="font-size: 44px;font-weight: bold;color: #ffffff;"></div>

        </div>
    </section>
    <section style="background-color: #f3f3f3">
        <div class="htable" style="height: 64px;justify-content:center;align-items:center;font-size: 16px;">
            <div class="hcell" style="padding-right:42px;border-right: 1px solid rgba(20,20,20,0.7);font-weight: bold;">光伏路灯</div>
            <div  v-if="hsccotr && hsccotr.length>0 " class="hcell" style="margin-left: 42px;"><span class="mgd" @click="goitem('hsccotr')">太阳能路灯控制器</span></div>
            <div  v-if="gprscotr && gprscotr.length>0 " class="hcell" style="margin-left: 80px;"><span class="mgd" @click="goitem('gprscotr')">GPRS智能路灯控制器</span></div>
            <div  v-if="g5cotr && g5cotr.length>0 " class="hcell" style="margin-left: 80px;"><span class="mgd" @click="goitem('g5cotr')">5G智能终端控制器</span></div>
        </div>
    </section>

    <section style="margin-top: 90px;" id="hsccotr" v-if="hsccotr && hsccotr.length>0 ">
        <div class="body1660 htable">
            <div class="hcell" style="width: 130px;text-align: left;">
                <div><span class="btLine">太阳</span>能路灯控<br/>制器</div>
            </div>
            <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                <div class="htable" style="justify-content: space-between;">
                    <cpblock_two :param="hsccotr[0]" />
                    <cpblock_one :param="hsccotr[1]"  />
                </div>
                <div class="htable" style="justify-content: space-between;margin-top: 25px;" v-if="hsccotr[2]" >
                    <cpblock_one :param="hsccotr[2]"  />
                    <cpblock_one :param="hsccotr[3]"  />
                    <cpblock_one :param="hsccotr[4]"  />
                </div>
                <div style="height: 45px;"></div>
            </div>
            <div class="hcell" style="width: 130px;"></div>
        </div>
    </section>

    <section style="margin-top: 45px;" id="gprscotr" v-if="gprscotr && gprscotr.length>0 ">
        <div class="body1660 htable">
            <div class="hcell" style="width: 130px;text-align: left;">
                <div><span class="btLine">GPRS</span>智能路灯<br/>控制器</div>
            </div>
            <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                <div class="htable" style="justify-content: space-between;">
                    <cpblock_two :param="gprscotr[0]" />
                    <cpblock_one :param="gprscotr[1]"  />
                </div>
                <div style="height: 45px;"></div>
            </div>
            <div class="hcell" style="width: 130px;"></div>
        </div>
    </section>

    <section style="margin-top: 45px;" id="g5cotr" v-if="g5cotr && g5cotr.length>0 ">
        <div class="body1660 htable">
            <div class="hcell" style="width: 130px;text-align: left;">
                <div><span class="btLine">5G智</span>能终端控<br/>制器</div>
            </div>
            <div class="hcell" style="width: 1400px;">
                <div class="htable" style="justify-content: space-between;">
                    <cpblock_two :param="g5cotr[0]" />
                    <cpblock_one :param="g5cotr[1]"  />

                </div>
            </div>
            <div class="hcell" style="width: 130px;"></div>
        </div>
    </section>

    <section style="height: 90px;"></section>
</div>
</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import cpblock_two  from  '../components/cpblock_two.vue'
    import cpblock_one  from  '../components/cpblock_one.vue'
    import { listTopicBases } from "../hts/api.js"
    import mydata from '../static/data/gflamp.json'
    const param = {
        pageindex: 2,
        rows: 6,
        page: 1,
    }
    export default {
        name: "gflamp" ,
        components: {
            myhead,
            cpblock_two,
            cpblock_one
        },
        data() {
            return {
                param,
                hsccotr:mydata.hsccotr,
                gprscotr: mydata.gprscotr,
                g5cotr: mydata.g5cotr,
            }
        },
        mounted() {
            //this.getList()
        },
        methods: {
            getList () {
                // console.log(this.id)
                listTopicBases({type:'gflamp:[hsccotr,gprscotr,g5cotr]', page: this.param.page, rows: this.param.rows })
                    .then(resData => {
                        this.hsccotr=resData.data.hsccotr
                        this.gprscotr=resData.data.gprscotr
                        this.g5cotr=resData.data.g5cotr
                    })
                    .catch()
            },
            goitem(id){
                document.getElementById(id).scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                });
            },
            goscheme(id) {
                this.$router.push({ path: 'scheme', query:{id:id} });
            },
        },
    }
</script>

<style scoped>
    .bannergflamp{
        background-image: url('~assets/banner21.png');
        height: 600px;
    }
    .mgd {
        cursor:pointer;
        padding: 0 5px;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        /*border-radius: 5px;*/
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 20px;
    }

</style>
